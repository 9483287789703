<script>
import { reverse } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "TestHistory",
  props: {},
  methods: {
    getStatus(asset) {
      if (asset.status === 1) {
        return "success";
      }
      return "danger";
    },
  },
  computed: {
    ...mapGetters("TestingHistoryModule", { getQueue: "getQueue" }),
    the_items() {
      return reverse([...(this.getQueue || [])]);
    },
    has_items() {
      return this.the_items.length > 0;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h4>
        <b-badge variant="dark" v-if="!has_items"
          >Start testing to see Assets here</b-badge
        >
        <b-badge
          v-for="asset in the_items"
          :key="asset.code"
          :variant="getStatus(asset)"
          :to="{ name: 'asset_test.edit', params: { id: asset.id } }"
          target="new_test"
          class="px-4 mx-1"
          >{{ asset.code }}</b-badge
        >
      </h4>
    </div>
  </div>
</template>

<style scoped></style>
